import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { DepartureColorIcon, DepartureWhiteIcon, TimeTableColorIcon, TimeTableWhiteIcon, TrafficinfoColorIcon, TrafficinfoWhiteIcon } from '../icons/Icons';
import "../styles/Navigation.css"
class Navigation extends Component {
    render() {
        return (
            this.props.location.pathname.includes("tripRequest") || this.props.location.pathname === "/" || (this.props.location.pathname.includes("departure") && !this.props.location.pathname.includes("departureDetail")) || this.props.location.pathname.includes("trafficInfos") ? (
                <div className='flex Navigation'>
                    <Link to="/tripRequest" className={`navigation-icon ${this.props.location.pathname.includes("tripRequest") || this.props.location.pathname === "/" ? "selected" : ""}`}>
                        {this.props.location.pathname.includes("tripRequest") || this.props.location.pathname === "/" ? <TimeTableColorIcon height="40px" /> : <TimeTableWhiteIcon height="40px" />}
                        <p className="sr-only">
                            Fahrplanauskunft
                        </p>
                    </Link>

                    <Link to="/departure" className={`navigation-icon ${this.props.location.pathname.includes("departure") ? "selected" : ""}`}>
                        {this.props.location.pathname.includes("departure") ? <DepartureColorIcon height="40px" /> : <DepartureWhiteIcon height="40px" />}
                        <p className="sr-only">
                            Abfahrtsmonitor
                        </p>
                    </Link>
                    <Link to="/trafficInfos" className={`navigation-icon ${this.props.location.pathname.includes("trafficInfos") ? "selected" : ""}`}>
                        {this.props.location.pathname.includes("trafficInfos") ? <TrafficinfoColorIcon height="40px" /> : <TrafficinfoWhiteIcon height="40px" />}
                        <p className="sr-only">
                            Verkehrsinfos
                        </p>
                    </Link>
                </div>
            ) : null
        )
    }
}

export default withRouter(Navigation);