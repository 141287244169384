import React, { Component } from "react";
import { connect } from "react-redux";
import { alertMessage } from "../actions";
import { AlertIcon, CancleCircleIconInverted } from "../icons/Icons";
import "../styles/Alert.css";

class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.show && this.props.message) {
      this.setState({ show: true });
      this.setState({ message: this.props.message });
      setTimeout(() => {
        this.setState({ show: false });
        this.props.resetMessage(null);
      }, 10000);
    }
  }

  render() {
    if (this.state.show) {
      return (
        <div className={`Alert ${this.props.className}`}>
          <AlertIcon style={{ height: "60px" }} />
          <div style={{ textAlign: "left" }}>{this.state.message}</div>
          <CancleCircleIconInverted
            style={{ cursor: "pointer", height: "120px" }}
            onClick={() => {
              this.setState({ show: false });
              this.props.resetMessage(null);
            }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

let mapStateToProps = (state) => {
  return {
    message: state.alert,
  };
};

let mapDispatchToProps = {
  resetMessage: alertMessage,
};
let AlertPopup = connect(mapStateToProps, mapDispatchToProps)(Alert);

export default AlertPopup;
