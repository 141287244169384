import axios from "axios";

/**
 * in dieser Datei sind alle actions, die im state gespeichert werden, welche von den Seiten aufgerufen werden
 * Bsp.: Die aktuelle Position des Users wird im Location Finder angefragt und für die restliche Anwendung
 * über die Action setUserPosition im state gespeichert.
 */

function errorHandler(err) {
  return { type: "ALERT_MESSAGE", message: err };
}

/**
 * sets the user position
 * @param {position of the user} position : ;
 * @returns action to reducer
 */
export function setUserPosition(position) {
  return { type: "SET_USER_POSITION", position: position };
}

export function setTripConfig(config) {
  return { type: "SET_TRIP_CONFIG", config: config };
}

/**
 *
 * @param {message of alert} message
 * @returns action to reducer
 */
export function alertMessage(message) {
  return { type: "ALERT_MESSAGE", message: message };
}

/**
 * sets the origin for a trip request
 * @param {origin for a tripRequest} data
 * @returns action to reducer
 */
export function setOrigin(data) {
  return { type: "SET_ORIGIN", location: data };
}

/**
 * sets the destination for a trip request
 * @param {destination for tripRequest} data
 * @returns action to reducer
 */
export function setDestination(data) {
  return { type: "SET_DESTINATION", location: data };
}

/**
 * sets the station which one should be highlighted
 * @param {station to highlight} station
 * @returns action to reducer
 */
export function setSelectedStation(station) {
  return { type: "SET_SELECTED_STATION", station: station };
}

/**
 * sets the date for the tripRequest
 * @param {date for trip request YYYY-MM-DD} data
 * @returns action to reducer
 */
export function setTripDate(data) {
  return { type: "SET_TRIPDATE", date: data };
}

/**
 * sets the time for the tripRequest
 * @param {time for trip request hh:mm} data
 * @returns action to reducer
 */
export function setTripTime(data) {
  return { type: "SET_TRIPTIME", time: data };
}

/**
 * sets if trip is arrival (arr) or departure (dep)
 * @param {arr | dep} data
 * @returns action to reducer
 */
export function setTripArr(data) {
  return { type: "SET_TRIPARR", arr: data };
}

/**
 * sets selected trip which is drawn on map
 * @param {trip out of tripRequest} trip
 * @returns a single trip
 */
export function setTrip(trip) {
  return { type: "SET_TRIP", trip: trip };
}

/**
 * gets the departure info of a given station
 * @param {id of station} keyword
 * @returns departure info to a given station
 */
export function getDepartureInfo(keyword) {
  if (keyword !== "") {
    return function (dispatch) {
      axios
        .get("/departure/" + keyword)
        .then((data) => {
          dispatch({
            type: "DEPARTURE_SEARCH",
            keyword: keyword,
            res: data.data,
          });
        })
        .catch((err) => {
          dispatch(
            errorHandler(
              "Haltestelleninformationen konnten nicht geladen werden."
            )
          );
        });
    };
  } else {
    return function (dispatch) {
      dispatch({ type: "DEPARTURE_SEARCH", keyword: keyword, res: null });
    };
  }
}

/**
 * gets the routes from the api
 * @returns list of routes
 */
export function getRoutes() {
  return function (dispatch) {
    axios
      .get("/routes")
      .then((data) => {
        dispatch({ type: "GET_ROUTES", res: data.data });
      })
      .catch((err) => {
        dispatch(errorHandler(""));
      });
  };
}

/**
 * gets the stations from the api
 * @returns list of stations
 */
export function setStations() {
  return function (dispatch) {
    axios
      .get("/stations")
      .then((data) => {
        dispatch({ type: "SET_STATIONS", keyword: null, res: data.data });
      })
      .catch((err) => {
        dispatch(errorHandler("Stationen konnten nicht geladen werden."));
      });
  };
}

/**
 * gets the locations from the apis
 * @returns list of locations
 */
export function setLocations() {
  return function (dispatch) {
    axios
      .get("/locations")
      .then((data) => {
        dispatch({ type: "SET_LOCATIONS", keyword: null, res: data.data });
      })
      .catch((err) => {
        dispatch(errorHandler("Standorte konnten nicht geladen werden"));
      });
  };
}

/**
 * sets the response of a given search string
 * @param {text | station name} keyword
 * @returns list of stations
 */
export function searchStop(keyword) {
  if (keyword !== "" && keyword.length > 2) {
    return function (dispatch) {
      axios
        .get("/stopFinder/" + keyword)
        .then((data) => {
          dispatch({
            type: "STOPFINDER_SEARCH",
            keyword: keyword,
            res: data.data,
          });
        })
        .catch((err) => {
          dispatch(errorHandler("Haltestelle konnte nicht geladen werden"));
        });
    };
  } else {
    return { type: "STOPFINDER_SEARCH", keyword: keyword, res: null };
  }
}

/**
 *
 * @returns an empty stopRequest to empty the list
 */
export function resetStopRequest() {
  return { type: "STOPFINDER_RESET" };
}
/**
 *
 * @returns an empty tripRequest to empty the list
 */
export function resetTripRequest() {
  return { type: "SET_TRIPS", trips: null };
}

/**
 *
 * @param {id of station | id of poi | coord} origin
 * @param {stop | poi | coord} originType
 * @param {id of station | id of poi | coord} destination
 * @param {stop | poi | coord} destinationType
 * @param {YYYY-MM-DD} date
 * @param {HH:MM} time
 * @param {arr | dep} arr
 * @returns tripRequest with multipl trips
 */
export function requestTrip(origin, destination, date, time, arr, config) {
  if (origin !== "" && destination !== "") {
    return function (dispatch) {
      axios
        .get(
          "/tripRequest/" +
            origin +
            "/" +
            destination +
            "/" +
            date +
            "/" +
            time +
            "/" +
            arr +
            "/" +
            JSON.stringify(config)
        )
        .then((data) => {
          if (data.status === 500) {
            dispatch(
              errorHandler(
                "Es konnte keine Verbindung gefunden oder geladen werden."
              )
            );
          }
          if (data) {
            dispatch({ type: "SET_TRIPS", trips: data.data });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            errorHandler(
              "Es konnte keine Verbindung gefunden oder geladen werden."
            )
          );
        });
    };
  } else {
    return { type: "SET_TRIPS", trips: null };
  }
}

/**
 *
 * @returns list of trips if session is still active
 */
export function requestNextTrip(origin, destination, dateTime, config) {
  if (origin !== "" && destination !== "") {
    return function (dispatch) {
      axios
        .get(
          "/tripRequest/" +
            origin +
            "/" +
            destination +
            "/" +
            dateTime.slice(0, 10).replace(/-/g, "") +
            "/" +
            dateTime.slice(11, 16) +
            "/dep/" +
            JSON.stringify(config)
        )
        .then((data) => {
          if (data) {
            dispatch({ type: "ADD_TRIPS", trips: data.data });
          }
        })
        .catch((err) => {
          dispatch(
            errorHandler(
              "Es konnte keine Verbindung gefunden oder geladen werden."
            )
          );
        });
    };
  }
}

/**
 *
 * @returns list of trips if session is still active
 */
export function requestPrevTrip(origin, destination, dateTime, config) {
  if (origin !== "" && destination !== "") {
    return function (dispatch) {
      axios
        .get(
          "/tripRequest/" +
            origin +
            "/" +
            destination +
            "/" +
            dateTime.slice(0, 10).replace(/-/g, "") +
            "/" +
            dateTime.slice(11, 16) +
            "/arr/" +
            JSON.stringify(config)
        )
        .then((data) => {
          if (data) {
            dispatch({ type: "ADD_TRIPS", trips: data.data });
          }
        })
        .catch((err) => {
          dispatch(
            errorHandler(
              "Es konnte keine Verbindung gefunden oder geladen werden."
            )
          );
        });
    };
  }
}

/**
 *
 * @returns trafficinfo list from api
 */
export function requestTrafficInfo() {
  return function (dispatch) {
    axios
      .get("/trafficinfos")
      .then((data) => {
        if (data) {
          dispatch({ type: "SET_TRAFFICINFO", res: data.data });
        }
      })
      .catch((err) => {
        dispatch(errorHandler("Verkehrsinfos konnten nicht geladen werden."));
      });
  };
}

/**
 *
 * @returns routes with trafficinfo list from api
 */
export function requestRoutesWithTrafficInfo() {
  return function (dispatch) {
    axios
      .get("/routeswithtrafficinfos")
      .then((data) => {
        if (data) {
          dispatch({ type: "SET_ROUTESWITHTRAFFICINFO", res: data.data });
        }
      })
      .catch((err) => {
        dispatch(errorHandler("Verkehrsinfos konnten nicht geladen werden."));
      });
  };
}

/**
 * sets the last search to prevent multipl request of same params
 * @param {last trip request} search
 * @returns string of last request
 */
export function lastSearch(search) {
  return { type: "SET_LASTSEARCH", lastSearch: search };
}

export function setCity(city) {
  return { type: "SET_CITY", city: city };
}
