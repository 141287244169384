/**
 * in dieser Datei werden die Actionen abgefagen und verarbeitet,
 * welche in actions/index.js abgefeuert werden.
 */

let initialState = [];

/**
 *
 */
let initialConfigState = {
  fastestRoute: true,
  leastChange: false,
  shortest: false,
  noStairs: false,
  noEscalator: false,
  noLift: false,
  lowFloorVehicles: false,
  entryAssistance: false,
  onlyWheelchairAccessible: false,
  walkSlow: false,
  walkNormal: true,
  walkFast: false,
  transportRe: true,
  transportSBahn: true,
  transportUBahn: true,
  transportTram: true,
  transportBus: true,
  transportOther: true,
  transportICE: false,
  transportIC: false,
  transportBicycle: false,
  transportFootpath: true,
  transportTaxi: false,
  transportBussi: false,
};

/**
 *
 * @param {the current state of the user position or []} state
 * @param {holds an action type and the new position of the user} action
 * @returns the updated state
 */
export function userPosition(state = initialState, action) {
  if (action.type === "SET_USER_POSITION") {
    state = action.position;
  }
  return state;
}

export function alertMessage(state = null, action) {
  if (action.type === "ALERT_MESSAGE") {
    state = action.message;
  }
  return state;
}

/**
 *
 * @param {the current state of the stations or []} state
 * @param {holds an action type and the new state of the stations} action
 * @returns the updated state
 */
export function setStations(state = initialState, action) {
  if (action.type === "SET_STATIONS" && action.res !== undefined) {
    state = action.res;
  }
  return state;
}

/**
 *
 * @param {the current state of the routes or []} state
 * @param {holds an action type and the new state of the routes} action
 * @returns the updated routes
 */
export function getRoutes(state = initialState, action) {
  if (action.type === "GET_ROUTES" && action.res !== undefined) {
    state = action.res;
  }
  return state;
}

/**
 *
 * @param {the current state of the locations or []} state
 * @param {holds an action type and the new state of the locations} action
 * @returns the updated state
 */
export function setLocations(state = initialState, action) {
  if (action.type === "SET_LOCATIONS" && action.res !== undefined) {
    state = action.res;
  }
  return state;
}

export function trafficInfo(state = initialState, action) {
  if (action.type === "SET_TRAFFICINFO" && action.res !== undefined) {
    state = action.res;
  }
  return state;
}

export function routesWithTrafficInfo(state = initialState, action) {
  if (action.type === "SET_ROUTESWITHTRAFFICINFO" && action.res !== undefined) {
    state = action.res;
  }
  return state;
}

export function setSelectedStation(state = null, action) {
  if (action.type === "SET_SELECTED_STATION" && action.station !== undefined) {
    state = action.station;
  }
  return state;
}

export function tripRequest(state = initialState, action) {
  if (action.type === "SET_TRIPS" && action.trips !== undefined) {
    if (action.trips) {
      return action.trips;
    } else {
      return [];
    }
  } else if (action.type === "ADD_TRIPS" && action.trips !== undefined) {
    if (action.trips) {
      return { ...state, data: state.data.concat(action.trips.data) };
    }
  }
  return state;
}

export function lastSearch(state = null, action) {
  if (action.type === "SET_LASTSEARCH" && action.lastSearch !== undefined) {
    state = action.lastSearch;
  }
  return state;
}

export function setOrigin(state = null, action) {
  if (action.type === "SET_ORIGIN" && action.location !== undefined) {
    state = action.location;
  }
  return state;
}

export function setDestination(state = null, action) {
  if (action.type === "SET_DESTINATION" && action.location !== undefined) {
    state = action.location;
  }
  return state;
}

export function setTripDate(state = null, action) {
  if (state == null) {
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    state = date.getFullYear() + "" + month + "" + day;
  }

  if (action.type === "SET_TRIPDATE" && action.date !== undefined) {
    state = action.date;
  }
  return state;
}

export function setTripTime(state = null, action) {
  if (state == null) {
    let date = new Date();
    let time = date.toTimeString();
    state = time.substring(0, 5);
  }
  if (action.type === "SET_TRIPTIME" && action.time !== undefined) {
    state = action.time;
  }
  return state;
}

export function setTripArr(state = "dep", action) {
  if (action.type === "SET_TRIPARR" && action.arr !== undefined) {
    state = action.arr;
  }
  return state;
}

export function drawTrip(state = initialState, action) {
  if (action.type === "SET_TRIP" && action.trip !== undefined) {
    return action.trip;
  }
  return state;
}

export function departure(state = initialState, action) {
  if (action.type === "DEPARTURE_SEARCH" && action.res !== undefined) {
    return action.res;
  } else {
    return state;
  }
}

export function stopFinder(state = initialState, action) {
  if (action.type === "STOPFINDER_SEARCH" && action.res !== undefined) {
    return action.res;
  } else if (action.type === "STOPFINDER_RESET") {
    return null;
  } else {
    return state;
  }
}

export function tripConfig(state = initialConfigState, action) {
  if (action.type === "SET_TRIP_CONFIG" && action.config !== undefined) {
    return action.config;
  } else {
    return state;
  }
}

export function setCity(state = "e", action) {
  if (action.type === "SET_CITY" && action.res !== undefined) {
    return action.city;
  } else {
    return state;
  }
}
