import React, { Component, lazy, Suspense } from 'react'
import { HashRouter, Route } from "react-router-dom"
import { connect } from 'react-redux';
import { getRoutes, setLocations, setStations } from '../actions';
import MediaQuery from "react-responsive";
import Navigation from './Navigation';
import Alert from '../components/Alert';
import {SpinningCircles} from 'react-loading-icons';
const TripRequest = lazy(() => import('./TripRequest')) ;
const TripList = lazy(() => import('./TripList'));
const Departure = lazy(() => import('./Departure'));
const DepartureDetail = lazy(() => import('./DepartureDetail'));
const TrafficInfos = lazy(() => import('./TrafficInfoView'));
const LocationFinder = lazy(() => import('./LocationFinderPage'));
const TripInfoPage = lazy(() => import('./TripInfoPage'));
const MapView = lazy(() => import('./MapView'));
const TripCarouselView = lazy(() => import('./TripCarousel'));
const TicketBuy = lazy(() => import('./TicketBuy'));
const TaxiBusBooking = lazy(() => import('./TaxiBusBooking'));
const AccessibleDetail = lazy(() => import('./AccessibleDetail'));
const Config = lazy(() => import('./Config'));
const TripplanerHomepage = lazy(() => import('./TripplanerHomepage'));

const renderLoader = () => <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '5px'}}><SpinningCircles height= "30px" stroke='#003366' fill='#003366'/><div style={{fontSize: '18px'}}>Daten werden geladen</div></div>;

class App extends Component {

    constructor(props) {
        super(props);
        props.setStations();
        props.setLocations();
        props.getRoutes();

        setTimeout(() => {
            props.setStations();
            props.getRoutes();
        }, 60000);
    }

    renderLoader() {
        <p>
            Loading
        </p>
    }

    render() {
        return (
            <HashRouter>
                <div className="noSlider flex" style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
                    <Alert message="Trip konnte nicht geladen werden" className="warning" />
                    <MediaQuery minWidth={1224}>
                        <div style={{ width: '30%', maxWidth: '30%', minWidth: '30%' }}>
                            <Navigation />
                            {/*renderLoader()*/}
                            <Suspense fallback={renderLoader()}><Route exact={true} path="/" component={TripRequest} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripRequest" component={TripRequest} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripList" component={TripList} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripCarousel" component={TripCarouselView} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/departure" component={Departure} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/locationFinder" exact name="content" component={LocationFinder} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/departureDetail" component={DepartureDetail} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/trafficInfos" component={TrafficInfos} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripInfo" component={TripInfoPage} /> </Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/ticket" component={TicketBuy} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/taxiBusBooking" component={TaxiBusBooking} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/accessible" component={AccessibleDetail} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/config" component={Config} /></Suspense>
                        </div>
                        <div style={{ height: "100vh", width: '70%' }}>
                            <Suspense fallback={renderLoader()}><MapView center={[51.455, 7.011]} zoom="12" /></Suspense>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={180}>
                        <Suspense fallback={renderLoader()}><TripplanerHomepage /></Suspense>
                    </MediaQuery>
                    <MediaQuery maxWidth={1223} minWidth={181}>
                        <div style={{ height: '100vh', width: '100vw', maxWidth: '100%' }}>
                            <Navigation />
                            <Suspense fallback={renderLoader()}><Route exact={true} path="/" component={TripRequest} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripRequest" component={TripRequest} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripList" component={TripList} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripCarousel" component={TripCarouselView} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/departure" component={Departure} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/locationFinder/" component={LocationFinder} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/departureDetail" component={DepartureDetail} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/trafficInfos" component={TrafficInfos} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/ticket" component={TicketBuy} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/taxiBusBooking" component={TaxiBusBooking} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/tripInfo" component={TripInfoPage} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/accessible" component={AccessibleDetail} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/config" component={Config} /></Suspense>
                            <Suspense fallback={renderLoader()}><Route path="/map">
                                <MapView style={{ height: '100vh', width: '100vw' }} center={[51.455, 7.011]} zoom="12" />
                            </Route></Suspense>
                        </div>
                    </MediaQuery>
                </div>
            </HashRouter>
        )
    }
}

let mapStateToProps = (state) => {
    return {
    }
}

let mapDispatchToProps = {
    setStations: setStations,
    setLocations: setLocations,
    getRoutes: getRoutes
}

let AppContainer =
    connect(mapStateToProps, mapDispatchToProps)(App)

export default AppContainer;