import { ReactComponent as BusStop } from "../icons/busStop.svg";
import { ReactComponent as BusStopWheelChair } from "../icons/busStopWheelchair.svg";
import { ReactComponent as ChevronRight } from "../icons/chevronRight.svg";
import { ReactComponent as ChevronDown } from "../icons/chevronDown.svg";
import { ReactComponent as ChevronUp } from "../icons/chevronUp.svg";
import { ReactComponent as ChevronRightSmall } from "../icons/chevronRightSmall.svg";
import { ReactComponent as ChevronLeft } from "../icons/chevronLeft.svg";
import { ReactComponent as ChevronLeftSmall } from "../icons/chevronLeftSmall.svg";
import { ReactComponent as Close } from "../icons/closeIcon.svg";
import { ReactComponent as UBahn } from "../icons/ubahnIcon.svg";
import { ReactComponent as UBahnNoBorder } from "../icons/ubahnIconNoBorder.svg";
import { ReactComponent as SBahn } from "../icons/sbahnIcon.svg";
import { ReactComponent as SBahnNoBorder } from "../icons/sbahnIconNoBorder.svg";
import { ReactComponent as Tram } from "../icons/tramIcon.svg";
import { ReactComponent as TramNoBorder } from "../icons/tramIconNoBorder.svg";
import { ReactComponent as Bus } from "../icons/busIcon.svg";
import { ReactComponent as BusNoBorder } from "../icons/busIconNoBorder.svg";
import { ReactComponent as TaxiBus } from "./taxiBusIcon.svg";
import { ReactComponent as TaxiBusNoBorder } from "../icons/taxiBusIconNoBorder.svg";
import { ReactComponent as Footpath } from "../icons/footpathIcon.svg";
import { ReactComponent as StopWatch } from "../icons/stopWatchIcon.svg";
import { ReactComponent as Position } from "../icons/positionIcon.svg";
import { ReactComponent as Euro } from "../icons/euroIcon.svg";
import { ReactComponent as Home } from "../icons/homeIcon.svg";
import { ReactComponent as Poi } from "../icons/poiIcon.svg";
import { ReactComponent as RE } from "../icons/reIcon.svg";
import { ReactComponent as RENoBorder } from "../icons/reIconNoBorder.svg";
import { ReactComponent as cancleCircle } from "../icons/cancleCircleIcon.svg";
import { ReactComponent as cancleCircleInverted } from "../icons/cancleCircelIconInverted.svg";
import { ReactComponent as trafficInfo } from "./trafficInfo.svg";
import { ReactComponent as information } from "./informationIcon.svg";
import { ReactComponent as lightCircle } from "./lightCircleIcon.svg";
import { ReactComponent as solidCircle } from "./solidCircleIcon.svg";
import { ReactComponent as defectElevator } from "./defectElevatorIcon.svg";
import { ReactComponent as defectEscalator } from "./defectEscalatorIcon.svg";
import { ReactComponent as elevator } from "./elevatorIcon.svg";
import { ReactComponent as escalator } from "./escalatorIcon.svg";
import { ReactComponent as wheelChair } from "./wheelChairIcon.svg";
import { ReactComponent as envMap } from "./envMapIcon.svg";
import { ReactComponent as locationA } from "./loactionAIcon.svg";
import { ReactComponent as locationB } from "./loactionBIcon.svg";
import { ReactComponent as clock } from "./clockIcon.svg";
import { ReactComponent as stopWheelChair } from "./stopWheelChairIcon.svg";
import { ReactComponent as map } from "./mapIcon.svg";
import { ReactComponent as swap } from "./swapIcon.svg";
import { ReactComponent as tripRequest } from "./tripRequestIcon.svg";
import { ReactComponent as alert } from "./alertIcon.svg";
import { ReactComponent as star } from "./starIcon.svg";
import { ReactComponent as filledStar } from "./filledStarIcon.svg";
import { ReactComponent as settings } from "./settings.svg";
import { ReactComponent as lastTrip } from "./lastTripIcon.svg";
import { ReactComponent as timetableWhite } from "./timetableWhite.svg";
import { ReactComponent as timetableColor } from "./timetableColor.svg";
import { ReactComponent as trafficinfoWhite } from "./trafficinfoWhite.svg";
import { ReactComponent as trafficinfoColor } from "./trafficinfoColor.svg";
import { ReactComponent as departureWhite } from "./departureWhite.svg";
import { ReactComponent as departureColor } from "./departureColor.svg";
import { ReactComponent as circle } from "./circle.svg";
import { ReactComponent as circleFill } from "./circleFill.svg";
import L from "leaflet";

export const TimeTableWhiteIcon = timetableWhite;
export const TimeTableColorIcon = timetableColor;
export const TrafficinfoWhiteIcon = trafficinfoWhite;
export const TrafficinfoColorIcon = trafficinfoColor;
export const DepartureWhiteIcon = departureWhite;
export const DepartureColorIcon = departureColor;
export const BusStopIcon = BusStop;
export const BusStopWheelChairIcon = BusStopWheelChair;
export const ChevronRightIcon = ChevronRight;
export const ChevronDownIcon = ChevronDown;
export const ChevronUpIcon = ChevronUp;
export const ChevronRightSmallIcon = ChevronRightSmall;
export const ChevronLeftIcon = ChevronLeft;
export const ChevronLeftSmallIcon = ChevronLeftSmall;
export const CircleIcon = circle;
export const CircleFillIcon = circleFill;
export const UBahnIcon = UBahn;
export const UBahnIconNoBorder = UBahnNoBorder;
export const SBahnIcon = SBahn;
export const SBahnIconNoBorder = SBahnNoBorder;
export const TramIcon = Tram;
export const TramIconNoBorder = TramNoBorder;
export const TaxiBusIcon = TaxiBus;
export const TaxiBusIconNoBorder = TaxiBusNoBorder;
export const BusIcon = Bus;
export const BusIconNoBorder = BusNoBorder;
export const FootpathIcon = Footpath;
export const StopWatchIcon = StopWatch;
export const PositionIcon = Position;
export const EuroIcon = Euro;
export const CloseIcon = Close;
export const CancleCircleIcon = cancleCircle;
export const CancleCircleIconInverted = cancleCircleInverted;
export const HomeIcon = Home;
export const PoiIcon = Poi;
export const REIcon = RE;
export const REIconNoBorder = RENoBorder;
export const TrafficInfoIcon = trafficInfo;
export const InformationIcon = information;
export const LightCircleIcon = lightCircle;
export const SolidCircleIcon = solidCircle;
export const DefectElevatorIcon = defectElevator;
export const DefectEscalatorIcon = defectEscalator;
export const ElevatorIcon = elevator;
export const EscalatorIcon = escalator;
export const EnvMapIcon = envMap;
export const WheelChairIcon = wheelChair;
export const LocationAIcon = locationA;
export const LocationBIcon = locationB;
export const ClockIcon = clock;
export const StopWheelChairIcon = stopWheelChair;
export const MapIcon = map;
export const SwapIcon = swap;
export const TripRequestIcon = tripRequest;
export const AlertIcon = alert;
export const StarIcon = star;
export const FilledStarIcon = filledStar;
export const LastTripIcon = lastTrip;
export const SettingsIcon = settings;

let iconSizeLarge = 40;
let iconSize = 32;

export const iconStop = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#ffdd29'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill='#006633'>
    <path d="M254.4,48.3c-113.9,0-206.8,92.9-206.8,206.7c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2C463.6,143.7,369.8,48.3,254.4,48.3z M425.5,258.8c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1C83.3,160.8,160.1,84,254.4,84C349.9,84,427.6,162.9,425.5,258.8z"/>
    <polygon points="292.6,151.1 292.6,229.8 212,229.8 212,151.1 168.7,151.1 168.7,357.7 212,357.7 212,267.2 292.6,267.2 292.6,357.7 335.9,357.7 335.9,151.1 	"/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const iconStopWheelChair = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#ffdd29'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill='#006633'>
    <path d="M254.4,48.3c-113.9,0-206.8,92.9-206.8,206.7c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2C463.6,143.7,369.8,48.3,254.4,48.3z M425.5,258.8c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1C83.3,160.8,160.1,84,254.4,84C349.9,84,427.6,162.9,425.5,258.8z"/>
    <polygon points="292.6,151.1 292.6,229.8 212,229.8 212,151.1 168.7,151.1 168.7,357.7 212,357.7 212,267.2 292.6,267.2 292.6,357.7 335.9,357.7 335.9,151.1 	"/>
    </g>
    <g fill='#003366'>
    <rect width='200' height='200' x='256' y='256'/>
    </g>
    <g fill='white'>
    <path d="M336.5,311.7c6.3,0,11.5-5.1,11.5-11.5
	s-5.1-11.5-11.5-11.5s-11.5,5.1-11.5,11.5c0,0,0,0,0,0C325.1,306.6,330.2,311.7,336.5,311.7z M344.6,416.8
	c-19.7-0.1-35.5-16.2-35.3-35.9c0,0,0,0,0,0c0-10.4,4.5-20.3,12.4-27.2l-0.6-11.2c-21.2,13.4-27.5,41.5-14.1,62.7
	c8.2,13,22.4,20.9,37.7,21.2c18.1-0.8,34.2-11.9,41.2-28.6l-6.8-9.4C375.7,404.7,361.4,416.6,344.6,416.8L344.6,416.8z M414.5,394.8
	h-5.7l-22.9-31.5c-1.2-2.2-5.7-8.6-11.5-8.6h-25.8v-11.5h22.9c3,0,6.2-1.3,6.2-4.3s-3.1-4.3-6.2-4.3h-22.9v-11.5
	c-0.4-6.4-5.7-8.6-11.4-8.5s-8.6,2.7-8.6,8.5v40.1c0.5,6.4,5.2,8.6,11.5,8.6h34.4l20,25.8c1.3,2.1,5.7,8.6,5.7,8.6h14.3
	c3.2,0,5.7-2.6,5.7-5.7S417.6,394.8,414.5,394.8L414.5,394.8z"/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const iconElevator = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#28a745'>
    <circle cx="256" cy="256" r="200"/>
    </g>
    <g fill='white'>
    <path d="M122.7,123.6v0.9v16.9v229.1v8.5v9.4h161.5V379v-8.5V141.4v-16.9v-0.9H122.7z M266.3,370.6H140.5V141.4h125.8V370.6z"/>
        <path id="Icon_material-keyboard-arrow-down_00000028294938506365216530000012470531900172821649_" d="M310.8,271l34.1,31.4
            l34.1-31.4l10.5,9.7l-44.5,41.2l-44.5-41.2L310.8,271z"/>
        <path id="Icon_material-keyboard-arrow-down_00000153693187540567679480000014963959472738794921_" d="M310.8,238.3l34.1-31.4
            l34.1,31.4l10.5-9.7l-44.5-41.2l-44.5,41.2L310.8,238.3z"/>
        <circle cx="207.1" cy="178.6" r="15.2"/>
        <path d="M217.9,201.7L217.9,201.7l-1,0h-5.4h-4h-1.6h-4.8H197h-1.5l0,0.1c-10.8,0.8-19.3,10.5-19.3,22.4v42.5c0,3.3,2.5,6.1,5.6,6.1
            h4c1.1,0,2.2-0.1,3.2-0.3v0.3v70.1c0,3.3,2.5,6,5.6,6h4.1c3.1,0,5.6-2.7,5.6-6v-70.1h5.6v70.1c0,3.3,2.5,6.1,5.6,6.1h4
            c3.1,0,5.6-2.7,5.6-6.1v-70.1v-0.2c0.8,0.1,1.6,0.2,2.4,0.2h4c3.1,0,5.6-2.7,5.6-6.1v-43C237.2,211.8,228.7,202.3,217.9,201.7z"/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const iconElevatorDefect = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#dc3545'>
    <circle cx="256" cy="256" r="200"/>
    </g>
    <g fill='white'>
    <path d="M122.7,123.6v0.9v16.9v229.1v8.5v9.4h161.5V379v-8.5V141.4v-16.9v-0.9H122.7z M266.3,370.6H140.5V141.4h125.8V370.6z"/>
        <path id="Icon_material-keyboard-arrow-down_00000028294938506365216530000012470531900172821649_" d="M310.8,271l34.1,31.4
            l34.1-31.4l10.5,9.7l-44.5,41.2l-44.5-41.2L310.8,271z"/>
        <path id="Icon_material-keyboard-arrow-down_00000153693187540567679480000014963959472738794921_" d="M310.8,238.3l34.1-31.4
            l34.1,31.4l10.5-9.7l-44.5-41.2l-44.5,41.2L310.8,238.3z"/>
        <circle cx="207.1" cy="178.6" r="15.2"/>
        <path d="M217.9,201.7L217.9,201.7l-1,0h-5.4h-4h-1.6h-4.8H197h-1.5l0,0.1c-10.8,0.8-19.3,10.5-19.3,22.4v42.5c0,3.3,2.5,6.1,5.6,6.1
            h4c1.1,0,2.2-0.1,3.2-0.3v0.3v70.1c0,3.3,2.5,6,5.6,6h4.1c3.1,0,5.6-2.7,5.6-6v-70.1h5.6v70.1c0,3.3,2.5,6.1,5.6,6.1h4
            c3.1,0,5.6-2.7,5.6-6.1v-70.1v-0.2c0.8,0.1,1.6,0.2,2.4,0.2h4c3.1,0,5.6-2.7,5.6-6.1v-43C237.2,211.8,228.7,202.3,217.9,201.7z"/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const iconEscalator = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#28a745'>
    <circle cx="256" cy="256" r="200"/>
    </g>
    <g fill='#006633'>
    <g fill='white'>
    <circle cx="235.6" cy="170.7" r="17.1"/>
<path d="M320,172.9c-10.1-0.2-21.4,1.4-29.4,9.4l-38,38v-10.8c0-10.1-8.5-18.5-18.6-17.6c-8.8,0.7-15.7,8.1-15.7,17.1v45.6
	l-37.1,37.1H123v66.9h65c1.1,0,2.4,0.1,3.5,0c11.4,0,20.2-2.1,27.9-9.7l108.9-108.9h61.3v-66.9H320z M373,223.3h-46.2
	c-6.8,0.5-9.3,3.7-10.7,5l-0.6,0.6l0,0L208.5,335.9c-1.2,1.2-2.6,2.2-4,3c-2.5,1.5-5.3,2.3-8.4,2.3h-39.7h-7.9h-8.8v-33h16.7H186
	c3.6-1,6.4-3,7.8-4.3l33.8-33.8c0,0,0,0,0,0l25-25v-0.1l49.5-49.5c1.3-1.3,2.7-2.3,4.2-3c2.4-1.4,5.2-2.2,8.3-2.2l58.4,0.3V223.3z"
	/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const iconEscalatorDefect = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#dc3545'>
    <circle cx="256" cy="256" r="200"/>
    </g>
    <g fill='#006633'>
    <g fill='white'>
    <circle cx="235.6" cy="170.7" r="17.1"/>
<path d="M320,172.9c-10.1-0.2-21.4,1.4-29.4,9.4l-38,38v-10.8c0-10.1-8.5-18.5-18.6-17.6c-8.8,0.7-15.7,8.1-15.7,17.1v45.6
	l-37.1,37.1H123v66.9h65c1.1,0,2.4,0.1,3.5,0c11.4,0,20.2-2.1,27.9-9.7l108.9-108.9h61.3v-66.9H320z M373,223.3h-46.2
	c-6.8,0.5-9.3,3.7-10.7,5l-0.6,0.6l0,0L208.5,335.9c-1.2,1.2-2.6,2.2-4,3c-2.5,1.5-5.3,2.3-8.4,2.3h-39.7h-7.9h-8.8v-33h16.7H186
	c3.6-1,6.4-3,7.8-4.3l33.8-33.8c0,0,0,0,0,0l25-25v-0.1l49.5-49.5c1.3-1.3,2.7-2.3,4.2-3c2.4-1.4,5.2-2.2,8.3-2.2l58.4,0.3V223.3z"
	/>
    </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const kundencenter = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
<g fill='#ffdd29'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="#003366">
<ellipse transform="matrix(0.9793 -0.2022 0.2022 0.9793 -24.4239 58.7804)" cx="275.5" cy="148.9" rx="33.8" ry="33.8"/>
	<path d="M343.1,285.2c0.3,0.3,0.5,0.4,0.8,0.4h35.4c15.6,0,28.3-12.4,28.9-27.9v-1.1h-46.6l-33.6-38.1c-3.4-3.9-7.5-7.1-12.2-9.4
		c-44.3-21.9-76.2-1.4-84.4,4.7c-1.1,0.8-2.1,1.8-3.1,2.8l-53.7,53.7v43h-1.8v0h-69.1V348h304.4v-34.6h-89v0h-0.3v-55.9L343.1,285.2
		z M233.7,313.4h-1.2v0h-28.7v-8.1h0.1v-17.8l29.9-29.9V313.4z"/>
             </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const metropolRadRuhr = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
<g fill='#ED6737'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path d="M255.6,62.2c-106.9,0-194.1,87.2-194.1,194c0,106.9,87.2,194.1,194.1,194.1c106.9,0,194.1-87.2,194.1-194.1
   C449.7,149.4,362.5,62.2,255.6,62.2z M255.6,419.8c-90.4,0-164.1-73.7-164.1-164.1c0-90.3,73.7-164,164.1-164
   c70.1,0,130.1,44.3,153.6,106.4c-8.7-10.1-22.1-14.7-40.4-14.7c-25.1,0-39,5-53,13c-11-8-25.2-13-54-13c-23.5,0-58,8-62.7,26.3
   v142.2l38.7-0.5v-119c4-29,59-25,57,2v117h38v-119c0-27,56-23,57-2l1,118.3C361.1,391.6,311.6,419.8,255.6,419.8z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const parkAndRide = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#003366'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill="white">
	<path d="M111.6,187.6H151c28.3,0,43.1,15.7,43.1,41.7c0,26-14.8,41.9-43.1,41.9h-14.2v53.7h-25.2V187.6z M146.3,251.9
	c15.7,0,22.6-7.7,22.6-22.6c0-15-6.9-22.4-22.6-22.4h-9.4v45.1H146.3z"/>
<path d="M238.1,264.9H197v-17.3h41.1v-41.1h17.3v41.1h41.1v17.3h-41.1V306h-17.3V264.9z"/>
<path d="M308.2,187.6h42.9c24.4,0,40.9,11,40.9,36.2c0,17.9-11.2,29.9-26.6,32.3v0.6c5.1,0.8,10.8,3.1,15.1,15.9l17.9,52.3h-26.8
	l-13.6-44.7c-3.3-10.8-9.8-14.4-19.1-14.4h-5.7v59h-25.2V187.6z M343.6,247.4c13.4,0,22.4-6.9,22.4-20.9c0-15.7-8.3-19.7-22.4-19.7
	h-10.2v40.5H343.6z"/>
<rect x="248.8" y="23" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 422.6539 -88.8012)" width="13.9" height="287.8"/>
<rect x="248.8" y="201.7" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 601.3128 89.8577)" width="13.9" height="287.8"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const bikeAndRide = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#003366'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill="white">
	<path d="M131,335.5c0,25.6,21.1,46.3,47.2,46.3s47.2-20.7,47.2-46.3c0-16.4-8.7-30.8-21.8-39l6-13.3l32.4,43.6
	c-1.5,2.6-2.3,5.6-2.3,8.8c0,9.8,8,17.8,17.8,17.8c7.4,0,13.8-4.6,16.5-11.1l12.5-0.3c3.2,22.5,22.9,39.9,46.7,39.9
	c26.1,0,47.2-20.7,47.2-46.3c0-25.6-21.1-46.3-47.2-46.3c-7.5,0-14.6,1.7-20.9,4.8l-15.6-22.8l8.8-19.1c4.8-5,11.5-12.6,12.6-18.1
	c1.8-8.9-30.3-44.5-30.3-44.5l-1-1c-6.1-7.6-13.9-15.8-24-23.6c-12.9-10.1-24.9,17.5-24.9,17.5l1.8,40.9l-21.4,14.8l8.2,10.1
	l29.2-17.8v-24.9l28.5,27.1L251.5,266h-34.3l4.1-9c1,1.2,2.4,1.9,4.1,1.9c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3
	c-1.1,0-2.1,0.3-3,0.9c-1.3-1.8-3.5-4.5-5.9-4.5c-2.2,0-4.1,2.1-5.1,3.6l-0.2-0.1l-0.8,1.8l0,0l0,0l-18.1,41.3
	c-4.5-1.4-9.2-2.1-14.1-2.1C152.1,289.2,131,309.9,131,335.5z M287.1,266H281l9.4-7.4L287.1,266z M281.6,278.5l-16.4,37.1l3.5-37.1
	H281.6z M252.1,314.6l-29-36.1h29V314.6z M302.2,300.5c-8.7,7.4-14.7,18-16,29.9l-11.7,0c-0.6-2-1.5-3.8-2.7-5.4l18.8-40.9
	L302.2,300.5z M309.5,310.8l13.8,19.5l-24.3,0.1C300.2,322.8,304,316,309.5,310.8z M298.8,341.6l33.3-0.8l0.1-0.3
	c0.5,0.2,1.1,0.3,1.7,0.3c2.9,0,5.3-2.4,5.3-5.3c0-0.6-0.1-1.2-0.3-1.7l0.3,0l-20.1-29.3c4.5-2,9.5-3.2,14.7-3.2
	c19.7,0,35.6,15.6,35.6,34.9s-15.9,34.9-35.6,34.9C316.2,371.1,301.5,358.3,298.8,341.6z M143.5,336.1c0-19.3,15.9-34.9,35.6-34.9
	c2.9,0,5.7,0.4,8.4,1l-13.8,31.5c-1.5,3.1,0.3,7.2,3.5,8.3c1.8,0.6,5.7,0.2,7.1-2.9l14.4-32c9.6,6.3,15.9,16.9,15.9,29.1
	c0,19.3-15.9,34.9-35.6,34.9S143.5,355.4,143.5,336.1z"/>
<circle cx="236" cy="148.6" r="17.8"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const metropolRadRuhrBike = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#ED6737'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill="white">
	<path d="M131,335.5c0,25.6,21.1,46.3,47.2,46.3s47.2-20.7,47.2-46.3c0-16.4-8.7-30.8-21.8-39l6-13.3l32.4,43.6
	c-1.5,2.6-2.3,5.6-2.3,8.8c0,9.8,8,17.8,17.8,17.8c7.4,0,13.8-4.6,16.5-11.1l12.5-0.3c3.2,22.5,22.9,39.9,46.7,39.9
	c26.1,0,47.2-20.7,47.2-46.3c0-25.6-21.1-46.3-47.2-46.3c-7.5,0-14.6,1.7-20.9,4.8l-15.6-22.8l8.8-19.1c4.8-5,11.5-12.6,12.6-18.1
	c1.8-8.9-30.3-44.5-30.3-44.5l-1-1c-6.1-7.6-13.9-15.8-24-23.6c-12.9-10.1-24.9,17.5-24.9,17.5l1.8,40.9l-21.4,14.8l8.2,10.1
	l29.2-17.8v-24.9l28.5,27.1L251.5,266h-34.3l4.1-9c1,1.2,2.4,1.9,4.1,1.9c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3
	c-1.1,0-2.1,0.3-3,0.9c-1.3-1.8-3.5-4.5-5.9-4.5c-2.2,0-4.1,2.1-5.1,3.6l-0.2-0.1l-0.8,1.8l0,0l0,0l-18.1,41.3
	c-4.5-1.4-9.2-2.1-14.1-2.1C152.1,289.2,131,309.9,131,335.5z M287.1,266H281l9.4-7.4L287.1,266z M281.6,278.5l-16.4,37.1l3.5-37.1
	H281.6z M252.1,314.6l-29-36.1h29V314.6z M302.2,300.5c-8.7,7.4-14.7,18-16,29.9l-11.7,0c-0.6-2-1.5-3.8-2.7-5.4l18.8-40.9
	L302.2,300.5z M309.5,310.8l13.8,19.5l-24.3,0.1C300.2,322.8,304,316,309.5,310.8z M298.8,341.6l33.3-0.8l0.1-0.3
	c0.5,0.2,1.1,0.3,1.7,0.3c2.9,0,5.3-2.4,5.3-5.3c0-0.6-0.1-1.2-0.3-1.7l0.3,0l-20.1-29.3c4.5-2,9.5-3.2,14.7-3.2
	c19.7,0,35.6,15.6,35.6,34.9s-15.9,34.9-35.6,34.9C316.2,371.1,301.5,358.3,298.8,341.6z M143.5,336.1c0-19.3,15.9-34.9,35.6-34.9
	c2.9,0,5.7,0.4,8.4,1l-13.8,31.5c-1.5,3.1,0.3,7.2,3.5,8.3c1.8,0.6,5.7,0.2,7.1-2.9l14.4-32c9.6,6.3,15.9,16.9,15.9,29.1
	c0,19.3-15.9,34.9-35.6,34.9S143.5,355.4,143.5,336.1z"/>
<circle cx="236" cy="148.6" r="17.8"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const carSharing = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#003366'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill="white">
	<path d="M294.7,273.8h-4.3l-43.1-66.9c-2.2-3.4-5.9-5.4-10-5.4h-98.5c-4,0-7.8,2-10,5.4l-43.1,66.9h-4.3c-6.5,0-11.9,5.3-11.9,11.9
	v61.6c0,6.5,5.3,11.9,11.9,11.9h11.9V384c0,6.5,5.3,11.9,11.9,11.9h19c6.5,0,11.9-5.3,11.9-11.9v-24.9h104.3V384
	c0,6.5,5.3,11.9,11.9,11.9h19c6.5,0,11.9-5.3,11.9-11.9v-24.9h11.9c6.5,0,11.9-5.3,11.9-11.9v-61.6
	C306.5,279.1,301.2,273.8,294.7,273.8z M105.6,322.4c-8.2,0-14.8-6.6-14.8-14.8c0-8.2,6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8
	C120.5,315.7,113.8,322.4,105.6,322.4z M107.9,273.8l32.4-50.1c1.8-2.7,4.8-4.4,8-4.4h79.4c3.2,0,6.3,1.6,8,4.4l32.4,50.1H107.9z
	 M271.6,322.4c-8.2,0-14.8-6.6-14.8-14.8c0-8.2,6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8C286.4,315.7,279.8,322.4,271.6,322.4z"/>
<circle cx="332.4" cy="142.5" r="26.3"/>
<path d="M332.4,182c-17.6,0-52.7,8.8-52.7,26.3v13.2H385v-13.2C385,190.8,349.9,182,332.4,182z"/>
<circle cx="389.8" cy="163.2" r="26.3"/>
<path d="M389.8,202.7c-17.6,0-52.7,8.8-52.7,26.3v13.2h105.3v-13.2C442.5,211.5,407.4,202.7,389.8,202.7z"/>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const stadtmobil = new L.divIcon({
  html: `<svg viewBox="0 0 512 512">
    <g fill='#ff8a36'>
    <circle cx='256' cy='256' r='200' />
    </g>
    <g fill="white">
	<path d="M330.6,200L243,149.4c7.8,11.7,29.1,48.5,29.1,104c0,51.1-18.1,89.2-27,105l85.5-49.4l94.4-54.5L330.6,200z"/>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const BusCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#8B1260'>
<circle cx='256' cy='256' r='256' />
</g>
<g fill="white">
<circle cx='256' cy='256' r='150' />
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 4, iconSize / 4),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize / 2, iconSize / 2),
});

export const LargeBusCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#8B1260'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
	C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
	c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSizeLarge / 2, iconSizeLarge / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSizeLarge, iconSizeLarge),
});

export const SBahnCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#138128'>
<circle cx='256' cy='256' r='256' />
</g>
<g fill="white">
<circle cx='256' cy='256' r='150' />
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 4, iconSize / 4),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize / 2, iconSize / 2),
});

export const LargeSBahnCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#138128'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
	C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
	c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSizeLarge / 2, iconSizeLarge / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSizeLarge, iconSizeLarge),
});

export const UBahnCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <style type="text/css">
	        .st0{fill-rule:evenodd;clip-rule:evenodd;}
        </style>
        <g fill="#03548B">
            <circle cx='256' cy='256' r='256' />
        </g>
        <g fill='white'>
            <circle cx='256' cy='256' r='150' />
        </g>
    </svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 4, iconSize / 4),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize / 2, iconSize / 2),
});

export const LargeUBahnCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#03548B'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
	C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
	c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSizeLarge / 2, iconSizeLarge / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSizeLarge, iconSizeLarge),
});

export const TramCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill="#BE1414">
            <circle cx='256' cy='256' r='256' />
        </g>
        <g fill='white'>
            <circle cx='256' cy='256' r='150' />
        </g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 4, iconSize / 4),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize / 2, iconSize / 2),
});

export const LargeTramCircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#BE1414'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
	C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
	c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSizeLarge / 2, iconSizeLarge / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSizeLarge, iconSizeLarge),
});

export const RECircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill="#003366">
            <circle cx='256' cy='256' r='256' />
        </g>
        <g fill='white'>
            <circle cx='256' cy='256' r='150' />
        </g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 4, iconSize / 4),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize / 2, iconSize / 2),
});

export const PositionBullsEyeIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g fill="#4285f4">
<circle cx="256" cy="256" r="260"></circle>
</g>
<g fill="white">
<circle cx="256" cy="256" r="200"></circle>
</g>
<g fill="#4285f4">
<circle cx="256" cy="256" r="160"></circle>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const LargeRECircleIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
<g fill='#003366'>
<circle cx='256' cy='256' r='200' />
</g>
<g fill="white">
<path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
	C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
	c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
</g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSizeLarge / 2, iconSizeLarge / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSizeLarge, iconSizeLarge),
});

export const LocationAMapIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="icon-poi" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
     <style type="text/css">
     .st0{fill-rule:evenodd;clip-rule:evenodd;}
 </style> 
 <g fill='#14CC14'>
 <circle cx='256' cy='256' r='200' />
 </g>
 <g fill="white">
 <path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
     C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
     c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
     <path d="M291.3,310.2h-72l-15,50.4h-38.4l70.2-209.4h42l67.5,209.4H306L291.3,310.2z M283.2,280.8L256.8,183h-0.6l-28.8,97.8H283.2
         z"/>
 </g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});

export const LocationBMapIcon = new L.divIcon({
  html: `
    <svg version="1.1" id="icon-poi" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
     <style type="text/css">
     .st0{fill-rule:evenodd;clip-rule:evenodd;}
 </style>
 <g fill='#CC1414'>
 <circle cx='256' cy='256' r='200' />
 </g>
 <g fill="white">
 <path class="st0" d="M256,49.2C142.1,49.2,49.2,142.1,49.2,256c0,115.3,95.3,209.1,211.1,206.7c110.3-2.3,200-91.9,202.4-202.2
     C465.2,144.6,371.4,49.2,256,49.2z M427.1,259.7c-2,91.3-76.2,165.5-167.5,167.3c-95.9,2-174.7-75.7-174.7-171.1
     c0-94.2,76.9-171.1,171.1-171.1C351.5,84.9,429.2,163.8,427.1,259.7z"/>
     <path d="M198.2,151.1h61.2c43.2,0,63,16.2,63,51.6c0,33.3-21,43.2-34.8,48.3v0.6c16.2,3.6,39.6,15,39.6,50.1
         c0,38.1-27.9,58.8-65.1,58.8h-63.9V151.1z M235.4,238.8h19.8c18.9,0,30-14.1,30-31.2c0-17.7-12.6-28.2-30.9-28.2h-18.9V238.8z
          M235.4,331.2h21.3c20.7,0,32.1-12.6,32.1-32.1c0-18.3-9.6-32.1-33.3-32.1h-20.1V331.2z"/>
 </g>
</svg>`,
  className: "",
  iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
  popupAnchor: new L.Point(0, 0),
  iconSize: new L.Point(iconSize, iconSize),
});
