import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import reduce from "./reducers/index";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import App from "./views/App";
import "leaflet/dist/leaflet.css";
import "./styles/index.css";

let store = createStore(reduce, applyMiddleware(thunkMiddleware));

/*Sentry.init({
  dsn: "https://f31156ae6fb847bb80e6f2dffe39d1e8@o384712.ingest.sentry.io/6008721",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.1,
  max_breadcrumbs: 50,
});*/

let container = document.getElementById("root");
let root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
