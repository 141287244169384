import { combineReducers } from "redux";
import {
  alertMessage,
  stopFinder,
  userPosition,
  setCity,
  setStations,
  departure,
  tripRequest,
  tripConfig,
  drawTrip,
  setOrigin,
  setDestination,
  lastSearch,
  trafficInfo,
  setTripDate,
  setTripTime,
  setTripArr,
  setSelectedStation,
  setLocations,
  getRoutes,
  routesWithTrafficInfo,
} from "./reducerFunctions";

let reduce = combineReducers({
  userPosition: userPosition,
  stations: setStations,
  city: setCity,
  routes: getRoutes,
  locations: setLocations,
  stopFinder: stopFinder,
  departure: departure,
  tripRequest: tripRequest,
  selectedTrip: drawTrip,
  originTrip: setOrigin,
  destinationTrip: setDestination,
  dateTrip: setTripDate,
  timeTrip: setTripTime,
  arrTrip: setTripArr,
  lastSearch: lastSearch,
  trafficInfo: trafficInfo,
  routesWithTrafficInfo: routesWithTrafficInfo,
  selectedStation: setSelectedStation,
  tripConfig: tripConfig,
  alert: alertMessage,
});

export default reduce;
